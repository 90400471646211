import { useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthContext } from './context/AuthContext';
// routes
import LoginPage from "./pages/Login";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DashboardAppPage from "./pages/Dashboard";
import UserPage from "./pages/UserPage";
import PermisosPage from "./pages/Permisos";
import RolesPage from "./pages/Roles";
import UsuariosPage from "./pages/Usuarios";
import MarcasPage from "./pages/Marcas";
import ProspectoPage from "./pages/Prospecto";

// ----------------------------------------------------------------------
const ProtectRoute = ({ children }) => {
  const navigate = useNavigate()
  const { isAuth } = useContext(AuthContext)
  if (!isAuth) {
    navigate("/login")
  }
  return children
}

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="dashboard/*" element={<ProtectRoute><DashboardLayout /></ProtectRoute>}>
              <Route path="app" element={<DashboardAppPage />} />
              <Route path="user" element={<UserPage/>}/>
              <Route path="permisos" element={<PermisosPage/>}/>
              <Route path="roles" element={<RolesPage/>}/>
              <Route path="usuarios" element={<UsuariosPage/>}/>
              <Route path="marcas" element={<MarcasPage/>}/>
              <Route path='prospectos' element={<ProspectoPage/>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
