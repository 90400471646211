import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, InputLabel, FormControl, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { AuthContext } from '../../../context/AuthContext';
import ValidationSchema from './ShemaValidation';
import { SnackbarGlobal } from 'src/components/Snackbar';
import { loginAPI } from './services/api';


import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  const { activateAuth, loadUser, loadToken, isAuth } = useContext(AuthContext)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  let { handleSubmit, register, formState: {errors }} =useForm({ resolver: yupResolver(ValidationSchema)})

  useEffect(() => {
    if(isAuth) {navigate('/dashboard/app')}
  })

  const mutation = useMutation(loginAPI, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error"){
        setMessageSnackbar('Correo y/o contraseña incorrectos')
        setAuthError(true);
      } else {
        loadUser(JSON.stringify(data.user))
        loadToken(data.token)
        activateAuth()
        navigate("/dashboard/app")
      }
    }
  })

  const onSubmit = (params) => {
    setIsLoading(true)
    mutation.mutate(params)
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          {...register("email")}
          name="email" 
          label="Email address" 
        />

        <TextField
          {...register("password")}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton 
        fullWidth 
        size="large" 
        type="submit" 
        variant="contained" 
        onClick={handleSubmit(onSubmit)}
        sx={{my:2}}
      >
        Ingresar
      </LoadingButton>
      <SnackbarGlobal vertical='bottom' horizontal='center' severity="error" open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
    </>
  );
}
