
// ----------------------------------------------------------------------

const icon = (name) => <img src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('icono-dashboard'),
    role: []
  },
  {
    title: 'Postulaciones',
    path: '/dashboard/postulaciones',
    icon: icon('icono-postulaciones'),
    role: []
  },
  {
    title: 'Vacantes publicadas',
    path: '/dashboard/vacantes',
    icon: icon('icono-vacantes'),
    role: []
  },
  {
    title: 'Prospectos',
    path: '/dashboard/prospectos',
    icon: icon('icono-prospectos'),
    role: []
  },
  {
    title: 'Marcas',
    path: '/dashboard/marcas',
    icon: icon('icono-marcas'),
    role: []
  }
]

export const navSettings = [
  {
    title: 'Usuarios',
    path: '/dashboard/usuarios',
    icon: icon('icono-usuarios'),
    role: []
  },
  {
    title: 'Roles',
    path: '/dashboard/roles',
    icon: icon('icono-roles'),
    role: []
  },
  {
    title: 'Permisos',
    path: '/dashboard/permisos',
    icon: icon('icono-permisos'),
    role: []
  },
]