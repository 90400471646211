const isProduction = false
const API_BASE_URL_TEST = 'http://localhost:8000/api/v1';
const API_BASE_URL_PROD = 'https://grupo-argentilia-talentos-api-x7gq7zmvhq-uc.a.run.app';

export const API_BASE_URL = isProduction ? API_BASE_URL_PROD : API_BASE_URL_TEST

export const HEADERS_WITH_TOKEN = (token) => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
}

export const HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE = (token) => {
  return {
    'Authorization': `Bearer ${token}`
  }
}

