import {
  Container,
  Stack,
  Typography,
  Card,
  Paper,
  IconButton
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from "react-query";
import { getPermission } from './services/api';
import { useEffect, useState } from 'react';
import { MUIDataTableGlobalOptions } from 'src/helpers';
import { Edit as EditIcon} from '@mui/icons-material'

export default function PermisosPage() {
  const { t } =  useTranslation();
  const [dataFilter, setDataFilter] = useState(null)
  const queryClient = useQueryClient()
  const { data } = useQuery('permissionList', getPermission, { refetchOnWindowFocus: false })

  useEffect(() =>{
    if(data !== undefined && data !== null && data !== ""){
      setDataFilter(data.data)
    }
  },[data])

  const Actions = ({ id }) => {
    return (<div>
      <IconButton><EditIcon color="info" /></IconButton>
    </div>)
  }

  const columns = [
    {
      name: 'text_front',
      label: 'Nombre',
    },
    {
      name: 'group',
      label: 'Grupo',
    },
    {
      name: '_id',
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        download: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Actions id={value} />
          )
        }
      }
    }
  ]
  return(
    <>
      <Helmet>
        <title> Permisos | Talentos </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb ={5}>
          <Typography variant="h4" gutterBottom>
            Permisos
          </Typography>
        </Stack>

        <Card>
          {(dataFilter !== null) ?
            <MUIDataTable
              data={dataFilter}
              columns={columns}
              options={MUIDataTableGlobalOptions()}
            />
            :  <Paper sx={{ padding: 3 }}> Cargando...</Paper>
          }
        </Card>
      </Container>
    </>
  )
}