import { createContext, useState } from 'react'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('mqadtk'));
  const [globalUser, setGlobalUser] = useState(localStorage.getItem('mqadusr'));
  const [globalToken, setGlobalToken] = useState(localStorage.getItem('mqadtk'));
  const [titlePage, setTitlePage] = useState(false)

  const value ={
    isAuth,
    activateAuth: () => {
      setIsAuth(true)
      const token = localStorage.getItem('mqadtk');
      if(token !== null ){
        setIsAuth(true)
        setGlobalToken(token)
      }
    },
    logOut: () => {
      setIsAuth(false)
      setGlobalUser(false)
      setGlobalToken(false)
      localStorage.clear()
    },
    globalUser,
    loadUser: (data) => {
      setGlobalUser(data)
      localStorage.setItem('mqadusr', data);
    },
    globalToken,
    loadToken: (data) => {
      setGlobalToken(data)
      localStorage.setItem('mqadtk', data);
    },
    titlePage,
    setTitlePage,
  }

  return <AuthContext.Provider value={ value }>
    { children }
  </AuthContext.Provider>
}
