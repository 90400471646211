import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Correo electrónico es requerido')
    .email('Correo electrónico no válido'),
  password: Yup.string()
    .required('Contraseña es requerida')
})

export default ValidationSchema