import { Box } from "@mui/material";
// import { TableSearch } from "components/Table";
import { useMutation } from "react-query";

// export const isEmptyObject = (value) => {
//   return Object.keys(value).length === 0 && value.constructor === Object;
// }

export const MUIDataTableTexts = (t) => {
  return {
    body: {
      noMatch: t('muidt_body_no_match'),
      toolTip: t('muidt_body_tooltip_order'),
      columnHeaderTooltip: column => `${t('muidt_body_custom_tooltip_order')} ${column.label}`
    },
    pagination: {
      next: t('muidt_pagination_next'),
      previous: t('muidt_pagination_previous'),
      rowsPerPage: t('muidt_pagination_rowsperpage'),
      displayRows: t('muidt_pagination_displayrows'),
    },
    toolbar: {
      search: t('muidt_toolbar_search'),
      downloadCsv: t('muidt_toolbar_downloadcsv'),
      print: t('muidt_toolbar_print'),
      viewColumns: t('muidt_toolbar_viewcolumns'),
      filterTable: t('muidt_toolbar_filtertable'),
    },
    filter: {
      all: t('muidt_filter_all'),
      title: t('muidt_filter_title'),
      reset: t('muidt_filter_reset'),
    },
    viewColumns: {
      title: t('muidt_viewcolumnas_title'),
      titleAria: t('muidt_viewcolumnas_titlearia'),
    },
    selectedRows: {
      text: t('muidt_selectedrows_text'),
      delete: t('muidt_selectedrows_delete'),
      deleteAria: t('muidt_selectedrows_deletearia'),
    }
  }
}

export const MUIDataTableGlobalOptions = () => {
  return {
    filter: false,
    print: false,
    // textLabels: MUIDataTableTexts(t),
    selectableRows: "none",
  //   customSearchRender: (searchText, handleSearch, hideSearch, options) => {
  //     return (
  //       <Box sx={{ paddingTop: 2 }}>
  //         <TableSearch
  //           placeholder={t("users_search_placeholder_text")}
  //           searchAction={(e) => handleSearch(e.target.value)} />
  //       </Box>
  //     )
  //   }
  }
}

export const ReactQueryMutationFunctionHG = (action, watchAction) => useMutation(action, {
  onSuccess: (data) => {
    if (data.status === "error") { 
      watchAction(false, 'Error al guardar los datos', 'error') 
    } else { 
      watchAction(true, 'Acción realizada correctamente', 'success') 
    }
  }
})