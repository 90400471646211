import { API_BASE_URL } from "src/services/api/baseUrl";

export const loginAPI = async (params) =>
  fetch(
    `${API_BASE_URL}/auth/login`,
    {
      method: 'POST',
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify(params),
    }
  )
  .then(res => res.json())