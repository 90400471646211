import { API_BASE_URL, HEADERS_WITH_TOKEN } from '../../../services/api/baseUrl.js'

export const getPermission = async () => {
  const res = await fetch(
    `${API_BASE_URL}/permission`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('mqadtk'))
    }
  )
  return await res.json()
}