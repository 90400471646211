import ReactDOM from 'react-dom/client';

//
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './context/AuthContext';
import App from './App';

import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AuthProvider>
);



reportWebVitals();
