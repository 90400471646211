import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useQuery } from "react-query"
import { getProspectos } from "./services/api"
import { Card, Container, Paper, Stack, Typography } from "@mui/material"
import { MUIDataTableGlobalOptions } from "src/helpers"

export default function ProspectoPage(){
  const [dataFilter, setDataFilter] = useState(null)
  const { data } = useQuery('prospectoList', getProspectos, {refetchOnWindowFocus:false})

  const columns = [ 

  ]
  return (
    <>
      <Helmet>
        <title> Prospectos | Talentos </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb ={5}>
          <Typography variant="h4" gutterBottom>
            Prospectos
          </Typography>
        </Stack>

        <Card>
          {(dataFilter !== null) ?
            <MUIDataTableGlobalOptions
              data={dataFilter}
              columns={columns}
              options={MUIDataTableGlobalOptions()}
            />
            :  <Paper sx={{ padding: 3 }}> Cargando...</Paper>
          }
        </Card>
      </Container>
    </>
  )
}