import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { getRoles } from "./services/api"
import { 
  IconButton,
  Container,
  Stack,
  Typography,
  Card,
  Paper,
} from "@mui/material"
import { Edit as EditIcon, Delete as DeleteIcon, Lock as LockIcon } from '@mui/icons-material'
import { Helmet } from "react-helmet-async"
import MUIDataTable from "mui-datatables"
import { MUIDataTableGlobalOptions } from 'src/helpers';


export default function RolesPage() {
  const [dataFilter, setDataFilter] = useState(null)
  const { data } = useQuery('rolesList', getRoles, {refetchOnWindowFocus: false})

  useEffect(()=>{
    if(data !== undefined && data !== null && data !== ""){
      setDataFilter(data.data)
    }
  },[data])

  const Actions = ({ id }) => {
    return (<div>
      <IconButton><EditIcon color="info" /></IconButton>
    </div>)
  }

  const columns =[
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: '_id',
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        download: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Actions id={value} />
          )
        }
      }
    }
  ]
  return (
    <>
      <Helmet>
        <title> Roles | Talentos </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb ={5}>
          <Typography variant="h4" gutterBottom>
            Roles
          </Typography>
        </Stack>

        <Card>
          {(dataFilter !== null) ?
            <MUIDataTable
              data={dataFilter}
              columns={columns}
              options={MUIDataTableGlobalOptions()}
            />
            :  <Paper sx={{ padding: 3 }}> Cargando</Paper>
          }
        </Card>
      </Container>
    </>
  )
}